body {
  font-family: "sans-serif";
  background-color: #fff5fa;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 450px) {
  .hero-main {
    padding-top: 50px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  font-size: 24px;
  width: 100%;
}

.right-app-bar-link {
  font-size: 16px;
  color: white;
  margin-left: 12px;
  background-image: linear-gradient(to top, #2f4294 50%, transparent 50%);
  background-size: 100% 200%;
  -webkit-transition: background-position 0.5s;
  -moz-transition: background-position 0.5s;
  transition: background-position 0.5s;
}

.right-app-bar-link:hover {
  background-position: 0 100%;
}

.app-bar-color {
  background-color: #28282a;
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  height: 80vh;
}

@media screen and (max-width: 450px) {
  .hero-section {
    height: 85vh;
  }
}

.background-class {
  background-image: url(./Images/law_landing2.jpg);
  background-color: #7fc7d9;
  background-position: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}

.divorce-background-class {
  background-image: url(./Images/divorce2.jpg);
  background-color: #7fc7d9;
  background-position: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}

.bank-background-class {
  background-image: url(./Images/bank.jpg);
  background-color: #7fc7d9;
  background-position: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}

.crim-background-class {
  background-image: url(./Images/balance.jpg);
  background-color: #7fc7d9;
  background-position: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -2;
}

.backdrop {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: black;
  opacity: 0.5;
  z-index: -1;
}

.byline {
  margin-bottom: 32px;
}

.focus-area-section {
  display: flex;
  overflow: hidden;
  background-color: #fff5fa;
}

.focus-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meet-attorney {
  background-color: #28282a;
  padding-top: 64px;
  padding-bottom: 64px;
}

.cta-image {
  position: absolute;
  top: -28px;
  left: -28px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  max-width: 600px;
}

.image-dots {
  position: absolute;
  top: -67px;
  left: -67px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background-color: #fff5fa;
}

.cta-card {
  display: flex;
  justify-content: center;
  background-color: #4355a3;
  padding: 56px 24px;
}

.cta-card-content {
  max-width: 400px;
}

.contact-form {
  display: flex;
  background-repeat: no-repeat;
  padding-top: 32px;
}

.dashed-vertical-line {
  position: relative;
  box-sizing: border-box;
  border-top: 1px dashed rgba(153, 153, 151, 1);
  max-width: 199px;
  transform-origin: center 0.5px;
  height: 5px;
  transform: rotate(90deg);
  margin: 56px auto;
  padding-top: 16px;
}

.content {
  overflow-x: hidden;
}

@media screen and (max-width: 450px) {
  .contact-form {
    padding-top: 0px;
  }
}
